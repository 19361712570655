<template>
  <Popup :is-active="isOpen" name="qq">
    <div class="GameCondition">
      <h2 class="GameCondition__title">
        <img src="@/assets/img/prize.svg" alt="" />
        <span v-text="$t('BTN_CONDITION')" />
      </h2>
      <p class="GameCondition__description" v-html="condition" />
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/modules/Popup";

export default {
  name: "GameCondition",
  components: { Popup },
  props: {
    isOpen: Boolean,
    condition: String,
    t: Object,
  },
};
</script>

<style lang="scss">
@import "../assets/scss/respond-mixin";
.GameCondition {
  padding: 24px;
  color: var(--black-color);
  @include respond-to(md) {
    width: 600px;
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  &__description {
    color: rgba(60, 60, 67, 0.72);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
    br {
      display: inline;
    }
  }
}
</style>
