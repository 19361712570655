import { ref } from "vue";

export default function () {
  const loading = ref(false);

  function serialize(params = {}) {
    const result = [];
    for (const [key, value = ""] of Object.entries(params))
      if (key in params && value)
        result.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    return result.join("&");
  }

  function generateUrl(path = "/", params) {
    const body = process.env.VUE_APP_API_CORE;
    const queries = params ? "?" + serialize(params) : "";
    return body + path + queries;
  }

  async function $get(path = "/", query) {
    if (!path) {
      throw "[useFetch error]: path is required!";
    }
    loading.value = true;
    try {
      let result;
      const url = generateUrl(path, query);
      const res = await fetch(url);
      if (!res.ok) {
        throw res;
      }
      result = await res.json();
      return result.data;
    } finally {
      loading.value = false;
    }
  }

  return {
    $get,
    loading,
  };
}
