<template>
  <section class="GameResults">
    <div class="GameResults__users">
      <a
        v-text="mainAccount.username"
        :href="accountLink"
        target="_blank"
        class="GameResults__username"
      />
      <div
        v-if="isJointGame"
        class="GameResults__username GameResults__username--more"
        @click="isOpenTooltip = !isOpenTooltip"
        @mouseenter="isOpenTooltip = true"
        @mouseleave="isOpenTooltip = false"
      >
        <span v-text="$t('MORE', { botsCount: botsCount - 1 })" />
        <Tooltip :is-open="isOpenTooltip" class="GameResults__tooltip">
          <div v-for="bot in otherAccounts" :key="bot.id">
            <a
              v-text="bot.username"
              :href="`https://instagram.com/${bot.username.slice(1)}`"
              target="_blank"
            />
          </div>
        </Tooltip>
      </div>
    </div>
    <header class="GameResults__header">
      <h3 class="GameResults__header-title" v-text="$t('LEADERBOARD')" />
      <SearchInput
        class="GameResults__header-input"
        :model-value="searchName"
        @update:modelValue="$emit('update:searchName', $event)"
        @input="startTimer()"
        :loading="gameLoading"
      />
    </header>
    <div class="GameResults__table-wrapper" v-if="players.length">
      <table
        class="GameResults__table GameResults__table--head"
        :style="tableStyle"
      >
        <tr>
          <th v-text="$t('PLACE')" />
          <th v-text="$t('PLAYER')" />
          <th v-html="commentsSVG" v-if="settings.commentPoint > 0" />
          <th v-html="liveCommentsSVG" v-if="settings.liveCommentPoint > 0" />
          <th v-html="storyMentionSVG" v-if="settings.mentionPoint > 0" />
          <th
            v-html="platform === 'instagram' ? marksSVG : repostSVG"
            v-if="settings.feedMentionPoint > 0"
          />
          <th
            v-html="reactionsSVG"
            v-if="settings.reactionPoint > 0"
          />
          <th
              v-html="personSVG"
              v-if="settings.referralPoint > 0"
          />
          <th v-text="$t('SCORES')" />
        </tr>
      </table>
      <table
        class="GameResults__table GameResults__table--body"
        :style="tableStyle"
      >
        <tr v-for="item in players" :key="item.id">
          <td :title="item.place" v-text="item.place" />
          <td :title="item.client" v-text="item.client" />
          <td v-text="fmtNum(item.comments)" v-if="settings.commentPoint > 0" />
          <td
            v-text="fmtNum(item.liveComments)"
            v-if="settings.liveCommentPoint > 0"
          />
          <td v-text="fmtNum(item.marks)" v-if="settings.mentionPoint > 0" />
          <td
            v-text="fmtNum(item.feedMentions)"
            v-if="settings.feedMentionPoint > 0"
          />
          <td
            v-text="fmtNum(item.reactions)"
            v-if="settings.reactionPoint > 0"
          />
          <td
            v-text="fmtNum(item.countReferrals)"
            v-if="settings.referralPoint > 0"
          />
          <td v-text="item.scores" />
        </tr>
      </table>
    </div>
    <footer class="GameResults__footer">
      <p
        v-if="players && players.length"
        class="GameResults__players-length"
        v-text="`${pagination.totalItems} ${$t('PLAYERS')}`"
      />
      <Pagination
        class="GameResults__table-pagination"
        :pagination="pagination"
        :disalbed="gameLoading"
        @change="onPagination"
        v-if="pagination.lastPage > 1"
      />
    </footer>
  </section>
</template>

<script>
import SearchInput from "./SearchInput";
import Pagination from "./modules/Pagination/Pagination";
import { onMounted, onUnmounted, ref } from "vue";
import Tooltip from "@/components/modules/Tooltip";

const commentsSVG = `<svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.8989 17.8125C19.8431 17.6072 19.9664 17.3217 20.084 17.1159C20.1207 17.0546 20.1604 16.9951 20.2031 16.9378C21.2122 15.4075 21.75 13.6147 21.75 11.7816C21.7664 6.51984 17.4032 2.25 12.0079 2.25C7.30261 2.25 3.37495 5.50969 2.45714 9.83672C2.31963 10.4782 2.25018 11.1325 2.24995 11.7886C2.24995 17.0578 6.4448 21.3947 11.8401 21.3947C12.6979 21.3947 13.8557 21.1791 14.4871 21.0023C15.1185 20.8256 15.749 20.5913 15.9117 20.5284C16.078 20.4645 16.2547 20.4316 16.4329 20.4314C16.6276 20.4307 16.8204 20.4694 16.9996 20.5453L20.1792 21.6928C20.2488 21.7228 20.3226 21.7421 20.3981 21.75C20.4576 21.7498 20.5165 21.7378 20.5714 21.7147C20.6262 21.6916 20.676 21.6579 20.7177 21.6154C20.7594 21.5729 20.7923 21.5226 20.8145 21.4673C20.8366 21.4121 20.8475 21.353 20.8467 21.2934C20.8428 21.2412 20.8333 21.1895 20.8185 21.1392L19.8989 17.8125Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/></svg>`;
const liveCommentsSVG = `<svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.2153 20.7812C23.1503 20.5417 23.2941 20.2087 23.4314 19.9686C23.4741 19.897 23.5205 19.8276 23.5703 19.7608C24.7475 17.9754 25.375 15.8838 25.3749 13.7452C25.3941 7.60648 20.3038 2.625 14.0092 2.625C8.51971 2.625 3.93745 6.42797 2.86667 11.4762C2.70623 12.2246 2.62521 12.9879 2.62495 13.7534C2.62495 19.9008 7.51893 24.9605 13.8135 24.9605C14.8142 24.9605 16.165 24.7089 16.9017 24.5027C17.6383 24.2966 18.3739 24.0231 18.5636 23.9498C18.7577 23.8752 18.9638 23.8369 19.1717 23.8366C19.3989 23.8358 19.6238 23.881 19.8329 23.9695L23.5424 25.3083C23.6236 25.3433 23.7097 25.3658 23.7978 25.375C23.8672 25.3748 23.9359 25.3608 23.9999 25.3338C24.064 25.3069 24.122 25.2675 24.1707 25.218C24.2193 25.1684 24.2577 25.1097 24.2835 25.0452C24.3094 24.9807 24.3221 24.9118 24.3211 24.8423C24.3166 24.7814 24.3056 24.7211 24.2883 24.6624L23.2153 20.7812Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/><path d="M14 15.0012C13.4477 15.0012 13 14.5535 13 14.0012C13 13.4489 13.4477 13.0012 14 13.0012C14.5523 13.0012 15 13.4489 15 14.0012C15 14.5535 14.5523 15.0012 14 15.0012Z" fill="currentColor"/><path d="M16.2416 12.0086C16.7294 12.5577 16.9989 13.2666 16.9989 14.0011C16.9989 14.7356 16.7294 15.4445 16.2416 15.9936M11.7566 15.9936C11.2688 15.4445 10.9994 14.7356 10.9994 14.0011C10.9994 13.2666 11.2688 12.5577 11.7566 12.0086M17.8344 10.4158C18.7434 11.3882 19.249 12.6696 19.249 14.0008C19.249 15.3319 18.7434 16.6133 17.8344 17.5858M10.1644 17.5858C9.25545 16.6133 8.74982 15.3319 8.74982 14.0008C8.74982 12.6696 9.25545 11.3882 10.1644 10.4158" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const marksSVG = `<svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.5803 12.0002C15.3191 15.0602 13.2515 16.8002 10.9046 16.8002C8.55783 16.8002 6.9958 14.651 7.2216 12.0002C7.4474 9.34939 9.37628 7.2002 11.7231 7.2002C14.0699 7.2002 15.8055 9.36019 15.5803 12.0002V12.0002Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.4343 20.8085C13.8862 21.463 12.8715 21.5994 11.1369 21.5994C5.86799 21.5994 1.97597 17.3012 2.43726 11.9994C2.89856 6.6976 7.54948 2.39941 12.8183 2.39941C18.7534 2.39941 22.0161 6.31196 21.5575 11.5483C21.2155 15.4576 18.7328 16.584 17.4329 16.3521C16.21 16.134 15.2007 15.0201 15.3872 12.8885L15.8469 7.63851" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const personSVG = `<svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 13.999L13 14C14.0538 14 14.9181 14.8155 14.9945 15.8507L15 16V17.5C14.999 21 11.284 22 8.5 22C5.77787 22 2.1647 21.044 2.00545 17.7296L2 17.5V15.999C2 14.9452 2.8164 14.0809 3.85081 14.0045L4 13.999ZM15.22 14H20C21.0538 14 21.9181 14.8164 21.9945 15.8508L22 16V17C21.999 20.062 19.142 21 17 21C16.32 21 15.569 20.904 14.86 20.678C15.196 20.292 15.467 19.851 15.662 19.351C16.205 19.476 16.715 19.5 17 19.5L17.2665 19.494C18.2518 19.4509 20.3529 19.1306 20.4927 17.205L20.5 17V16C20.5 15.7547 20.3222 15.5504 20.0896 15.5081L20 15.5H15.949C15.865 14.9986 15.6554 14.5432 15.3545 14.1598L15.22 14H20H15.22ZM4 15.499L3.89934 15.509C3.77496 15.5343 3.69 15.6018 3.646 15.645C3.6028 15.689 3.53528 15.7733 3.51 15.898L3.5 15.999V17.5C3.5 18.509 3.95 19.222 4.917 19.742C5.74315 20.1869 6.91951 20.4563 8.18258 20.4951L8.5 20.5L8.8174 20.4951C10.0803 20.4563 11.2559 20.1869 12.083 19.742C12.9886 19.2545 13.4416 18.5974 13.4947 17.6849L13.5 17.499V16C13.5 15.7547 13.3222 15.5504 13.0896 15.5081L13 15.5L4 15.499ZM8.5 3C10.985 3 13 5.015 13 7.5C13 9.985 10.985 12 8.5 12C6.015 12 4 9.985 4 7.5C4 5.015 6.015 3 8.5 3ZM17.5 5C19.433 5 21 6.567 21 8.5C21 10.433 19.433 12 17.5 12C15.567 12 14 10.433 14 8.5C14 6.567 15.567 5 17.5 5ZM8.5 4.5C6.846 4.5 5.5 5.846 5.5 7.5C5.5 9.154 6.846 10.5 8.5 10.5C10.154 10.5 11.5 9.154 11.5 7.5C11.5 5.846 10.154 4.5 8.5 4.5ZM17.5 6.5C16.397 6.5 15.5 7.397 15.5 8.5C15.5 9.603 16.397 10.5 17.5 10.5C18.603 10.5 19.5 9.603 19.5 8.5C19.5 7.397 18.603 6.5 17.5 6.5Z" fill="currentColor"/></svg>`;
const storyMentionSVG = `<svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9993 1.99951C16.4679 1.99951 18.8764 2.76081 20.8964 4.17962C22.9165 5.59844 24.4499 7.60572 25.2874 9.92781C26.125 12.2499 26.2261 14.7738 25.5769 17.1555C24.9277 19.5371 23.5598 21.6606 21.6596 23.2364M19.4454 24.6925C17.5333 25.6664 15.3951 26.1097 13.2535 25.9763C11.1119 25.8429 9.04517 25.1378 7.26869 23.9342M5.2695 22.233C3.42403 20.2762 2.28945 17.7562 2.04785 15.0774M2.09833 12.4614C2.32424 10.7134 2.93241 9.03673 3.8796 7.55042M5.61411 5.41535C6.36112 4.68565 7.20017 4.05659 8.11002 3.54409" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M15.9927 18.9929C15.1002 19.3679 14.5152 19.446 13.5152 19.446C10.4777 19.446 8.23398 16.9835 8.49991 13.946C8.76585 10.9085 11.4471 8.44604 14.4846 8.44604C17.9062 8.44604 19.7871 10.6876 19.5227 13.6876C19.3255 15.9273 17.8943 16.5726 17.1449 16.4398C16.4399 16.3148 15.858 15.6767 15.9655 14.4554L16.2305 11.4476M15.9999 13.946C15.8593 15.5398 14.7462 16.446 13.4827 16.446C12.2193 16.446 11.3784 15.3267 11.4999 13.946C11.6215 12.5654 12.6599 11.446 13.9234 11.446C15.1868 11.446 16.1212 12.571 15.9999 13.946Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const reactionsSVG = `<svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.125 17.5C6.125 12.4141 12.9062 8.47656 11.375 2.625C14.9844 2.625 21.875 7.875 21.875 17.5C21.875 19.5886 21.0453 21.5916 19.5685 23.0685C18.0916 24.5453 16.0886 25.375 14 25.375C11.9114 25.375 9.90838 24.5453 8.43153 23.0685C6.95468 21.5916 6.125 19.5886 6.125 17.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 20.125C17.5 23.281 15.75 24.5 14 24.5C12.25 24.5 10.5 23.281 10.5 20.125C10.5 16.969 12.6875 15.4219 12.25 13.125C14.5469 13.125 17.5 16.969 17.5 20.125Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const likeSVG = `<svg width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5436 0.75C11.5005 0.75 10.0005 3.75 10.0005 3.75C10.0005 3.75 8.50048 0.75 5.45735 0.75C2.98423 0.75 1.02579 2.81906 1.00048 5.28797C0.948917 10.4128 5.06595 14.0573 9.5786 17.1202C9.70301 17.2048 9.85001 17.2501 10.0005 17.2501C10.151 17.2501 10.2979 17.2048 10.4224 17.1202C14.9345 14.0573 19.0516 10.4128 19.0005 5.28797C18.9752 2.81906 17.0167 0.75 14.5436 0.75V0.75Z" stroke="#9296AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const repostSVG = `<svg width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9L10.75 1.125V5.625C2.85484 5.625 1 11.2861 1 16.875C3.27859 13.9575 5.29375 12.375 10.75 12.375V16.875L19 9Z" stroke="#9296AD" stroke-width="1.5" stroke-linejoin="round"/></svg>`;

export default {
  name: "GameResults",
  components: { Tooltip, Pagination, SearchInput },
  props: {
    searchName: String,
    pagination: Object,
    gameLoading: Boolean,
    players: Array,
    settings: Object,
    bots: Array,
    platform: String,
  },
  emits: ["update:searchName", "update:pagination", "update"],
  data() {
    return {
      isOpenTooltip: false,
    };
  },
  setup(props, ctx) {
    let searchTimer = null;
    const isMobile = ref(false);
    const numberFormat = new Intl.NumberFormat("RU");
    const fmtNum = (value = 0) => numberFormat.format(value);

    function deviceCheck() {
      isMobile.value = window.innerWidth < 768;
    }
    function startTimer() {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(callFetch, 750);
    }

    function callFetch() {
      ctx.emit("update");
    }

    function onPagination(payload) {
      ctx.emit("update:pagination", payload);
      callFetch();
    }

    deviceCheck();

    onMounted(() => window.addEventListener("resize", deviceCheck));
    onUnmounted(() => window.removeEventListener("resize", deviceCheck));

    return {
      liveCommentsSVG,
      reactionsSVG,
      commentsSVG,
      marksSVG,
      personSVG,
      storyMentionSVG,
      likeSVG,
      repostSVG,
      isMobile,
      fmtNum,
      startTimer,
      onPagination,
    };
  },
  computed: {
    accountLink() {
      const account = this.mainAccount.username.slice(1)
      return this.platform === "instagram"
        ? "https://instagram.com/" + account
        : "https://t.me/" + account
    },
    isJointGame() {
      return this.botsCount > 1;
    },
    mainAccount() {
      return this.bots[0];
    },
    otherAccounts() {
      const accounts = [...this.bots];
      accounts.shift();
      return accounts;
    },
    botsCount() {
      return this.bots.length;
    },
    tableStyle() {
      let commentPoint = this.settings.commentPoint ? "minmax(60px, 1fr) " : "";
      let liveCommentPoint = this.settings.liveCommentPoint
        ? "minmax(60px, 1fr) "
        : "";
      let mentionPoint = this.settings.mentionPoint ? "minmax(60px, 1fr) " : "";
      let feedMentionPoint = this.settings.feedMentionPoint
        ? "minmax(60px, 1fr) "
        : "";
      let reactionPoint = this.settings.reactionPoint
        ? "minmax(60px, 1fr) "
        : "";
      let referralPoint = this.settings.referralPoint
        ? "minmax(60px, 1fr) "
        : "";

      return {
        "grid-template-columns":
          "minmax(70px, 1fr) minmax(194px, 2fr) " +
          commentPoint +
          liveCommentPoint +
          mentionPoint +
          feedMentionPoint +
          reactionPoint +
          referralPoint +
          "minmax(72px, 1fr)",
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/respond-mixin";
.GameResults {
  position: relative;

  &__users {
    display: flex;
    padding: 0 16px;
    @include respond-to(md) {
      padding: 0 20px;
    }
  }

  &__username {
    display: inline-flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
    &--more {
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid #ffffff;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__tooltip {
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
  }

  &__header {
    padding: 0 16px;
    margin-bottom: 20px;
    @include respond-to(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      margin-bottom: 32px;
    }
    &-title {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 32px;
      line-height: 39px;
      @include respond-to(md) {
        font-size: 44px;
        line-height: 53px;
      }
    }
    &-input {
      height: 40px;
      margin-top: 12px;
      @include respond-to(md) {
        width: 100%;
        max-width: 280px;
        height: 44px;
        margin-top: 0;
      }
      @include respond-to(lg) {
        max-width: 360px;
      }
    }
  }

  &__table-wrapper {
    overflow-x: auto;
    @include respond-to(md) {
      padding: 0 20px;
    }
  }

  &__table {
    $rowBorder: 1px solid rgba(255, 255, 255, 0.6);
    display: grid;
    border-collapse: collapse;
    width: min-content;
    @include respond-to(md) {
      width: 100%;
    }
    &--head {
    }
    &--body {
      border-top: $rowBorder;
      border-bottom: $rowBorder;
      @include respond-to(md) {
        border-radius: 32px;
        border: 2px solid var(--white-color);
      }
    }

    thead,
    tbody,
    tr {
      display: contents;
    }

    thead {
      padding-bottom: 8px;
    }

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 4px;
      text-align: left;
      &:nth-child(1) {
        padding-left: 16px;
        @include respond-to(md) {
          padding-left: 20px;
        }
      }
      &:last-child {
        padding-right: 16px;
        @include respond-to(md) {
          padding-right: 20px;
        }
      }
    }

    tr {
      &:not(:first-child) {
        td {
          border-top: $rowBorder;
        }
      }
    }

    th {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      height: 24px;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 8px;
      @include respond-to(md) {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 16px;
      }
    }

    td {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.2px;
      padding-top: 16px;
      padding-bottom: 16px;
      @include respond-to(md) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 22px;
    @include respond-to(md) {
      padding: 0 20px;
    }
  }

  &__players-length {
    text-transform: lowercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  &__table-pagination {
  }
}
</style>
