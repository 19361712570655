<template>
  <section v-if="point" class="CollapseWrapper" :class="{ 'CollapseWrapper--open': opened }">
    <header class="CollapseWrapper__header" @click="opened = !opened">
      <img :src="icon" alt="" class="CollapseWrapper__header-icon" />
      <h3 class="CollapseWrapper__header-title" v-text="title" />
      <svg
        class="CollapseWrapper__header-icon-arrow"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 17.5L14 9.625L22.75 17.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </header>
    <transition name="rules" v-if="htmlContent">
      <article
        class="CollapseWrapper__content"
        v-if="opened"
        v-html="htmlContent"
      />
    </transition>
    <transition name="rules" v-else>
      <article class="CollapseWrapper__content" v-if="opened">
        <slot />
      </article>
    </transition>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CollapseWrapper",
  props: {
    point: Number,
    title: String,
    icon: String,
    htmlContent: String,
  },
  setup() {
    const opened = ref(false);
    return {
      opened,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/respond-mixin";
.CollapseWrapper {
  &--open {
  }

  &__header {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    .CollapseWrapper--open & {
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      margin-right: 4px;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &-icon-arrow {
      transition: transform 250ms;
      transform: rotate(-180deg);
      width: 16px;
      height: 16px;
      color: var(--black-color);
      .CollapseWrapper--open & {
        transform: rotate(0);
      }
    }
  }

  &__content {
    overflow: hidden;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--black-color);
    ul {
      padding: 0 0 0 18px;
      margin: 0;
      li:empty {
        height: 24px;
        display: block;
        &::marker {
          content: none;
        }
      }
    }
  }
  @keyframes maxH {
    from {
      max-height: 0;
    }
    75% {
      max-height: 500px;
    }
    100% {
      max-height: none;
    }
  }
  .rules {
    &-enter-active,
    &-leave-active {
      transition: max-height 350ms;
    }

    &-enter-from,
    &-leave-to {
      max-height: 0;
    }
  }
}
</style>
