<template>
  <Popup :is-active="isOpen" name="qq">
    <div class="GamePoints">
      <h2 class="GamePoints__title">
        <img src="@/assets/img/cup.svg" alt="" />
        <span v-text="$t('BTN_POINTS')" />
      </h2>

      <p class="GamePoints__description" v-html="description" />

      <CollapseWrapper
        v-for="{ point, iconSrc, title, content } in GamePoints"
        :key="title"
        :point="point"
        :title="title"
        :icon="iconSrc"
        class="GamePoints__spoiler"
      >
        <div
          v-if="point"
          class="GamePoints__spoiler-content"
          v-html="content"
        />
      </CollapseWrapper>

      <ul class="GamePoints__footer">
        <li v-html="gameTypeText" />
        <li v-if="platform === 'instagram'" v-html="subscribersCountText" />
        <li v-html="resetTimerText" />
      </ul>
    </div>
  </Popup>
</template>

<script>
import { reactive } from "vue";
import CollapseWrapper from "./modules/CollapseWrapper";
import Popup from "@/components/modules/Popup";

export default {
  name: "GamePoints",
  components: { Popup, CollapseWrapper },
  props: {
    isOpen: Boolean,
    settings: Object,
    platform: String,
    bots: Array,
    t: Object,
  },
  setup(props) {
    const {
      commentLetterCount,
      commentPoint,
      commentPointMax,
      commentExtraPoints,
      commentExtraTime,
      liveCommentPoint,
      liveCommentPointMax,
      mentionPoint,
      mentionPointMax,
      feedMentionPoint,
      feedMentionMax,
      reactionPoint,
      reactionPointMax,
      reactionType,
    } = reactive(props.settings);
    const { platform } = reactive(props);
    const { t } = reactive(props);

    let commentSettings = {};
    let liveCommentSettings = {};
    let storiesMentionSettings = {};
    let feedMentionSettings = {};
    let reactionSettings = {};

    if (commentPoint > 0) {
      commentSettings = {
        point: commentPoint,
        iconSrc: require("@/assets/svg/chat.svg"),
        title: t("COMMENTS", { commentPoint: commentPoint }),
        content: t("COMMENT_TEXT", {
          commentLetterCount: commentLetterCount,
          commentPointMax: commentPointMax / commentPoint,
          commentExtraPoints: commentExtraPoints,
          commentExtraTime: commentExtraTime,
        }),
      };
    }

    if (liveCommentPoint > 0) {
      liveCommentSettings = {
        point: liveCommentPoint,
        iconSrc: require("@/assets/svg/live.svg"),
        title: t("LIVE_COMMENTS", { liveCommentPoint: liveCommentPoint }),
        content: t("LIVE_COMMENT_TEXT", {
          liveCommentPointMax: liveCommentPointMax / liveCommentPoint,
        }),
      };
    }

    if (mentionPoint > 0) {
      storiesMentionSettings = {
        point: mentionPoint,
        iconSrc:
          platform === "instagram"
            ? require("@/assets/svg/at.svg")
            : require("@/assets/svg/repost.svg"),
        title:
          platform === "instagram"
            ? t("STORY_MENTION", { mentionPoint: mentionPoint })
            : `«Поделиться» – ${mentionPoint} баллов`,
        content:
          platform === "instagram"
            ? t("MENTION_TEXT", {
                mentionPointMax: mentionPointMax / mentionPoint,
              })
            : `Баллы начисляются за уникальную «Поделиться публикацией». <br /> Можно получить не более ${mentionPointMax} баллов в сутки.`,
      };
    }

    if (feedMentionPoint > 0) {
      feedMentionSettings = {
        point: feedMentionPoint,
        iconSrc: require("@/assets/svg/person.svg"),
        title: `Отметка в ленте – ${feedMentionPoint} баллов`,
        content: `Баллы начисляются за уникальную отметку в ленте.  <br /> Можно получить не более ${feedMentionMax} баллов в сутки.`,
      };
    }

    if (reactionPoint > 0) {
      reactionSettings = {
        point: reactionPoint,
        iconSrc: require("@/assets/svg/flame.svg"),
        title:
          platform === "instagram"
            ? reactionType === 1
              ? t("STORY_REACTION", { reactionPoint: reactionPoint })
              : t("STORY_ANSWER", { reactionPoint: reactionPoint })
            : t("POST_REACTION", { reactionPoint: reactionPoint }),
        content:
          platform === "instagram"
            ? reactionType === 1
              ? t("STORY_REACTION_TEXT", {
                  reactionPointMax: reactionPointMax / reactionPoint,
                })
              : t("STORY_ANSWER_TEXT", {
                  reactionPointMax: reactionPointMax / reactionPoint,
                })
            : t("POST_REACTION_TEXT"),
      };
    }

    const GamePoints = [
      commentSettings,
      liveCommentSettings,
      storiesMentionSettings,
      feedMentionSettings,
      reactionSettings,
    ];
    return {
      GamePoints,
    };
  },
  computed: {
    description() {
      if (this.bots.length === 1) {
        if (this.platform === "instagram") {
          return this.$t("POINTS_MANUAL.DESCRIPTION_ONE", {
            account: this.accountList,
          });
        } else {
          return this.$t("POINTS_MANUAL.DESCRIPTION_TELEGRAM", {
            channel: this.settings.telegramChannelName,
            account: this.accountList,
          });
        }
      } else {
        return this.$t("POINTS_MANUAL.DESCRIPTION_MANY", {
          accounts: this.accountList,
        });
      }
    },
    accountList() {
      const result = [];
      const platform =
        this.platform === "instagram"
          ? "https://instagram.com/"
          : "https://t.me/";
      this.bots.forEach((bot) =>
        result.push(
          "<a href='" + platform +
            bot.username.slice(1) +
            "' target='_blank' class='GamePoints__account'>" +
            bot.username +
            "</a>"
        )
      );
      return result.join(" ");
    },
    gameTypeText() {
      return this.settings.gameType === 1
        ? this.$t("POINTS_CALCULATING_1")
        : this.$t("POINTS_CALCULATING_2");
    },
    subscribersCountText() {
      return this.settings.minSubscribersCount > 0
        ? this.$t("SUBSCRIBERS_COUNT_1", {
            minSubscribersCount: this.settings.minSubscribersCount,
          })
        : this.$t("SUBSCRIBERS_COUNT_2");
    },
    resetTimerText() {
      return this.$t("TIME_RESET", { clearTime: this.settings.clearTime });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/respond-mixin";
.GamePoints {
  padding: 24px;
  color: var(--black-color);
  @include respond-to(md) {
    width: 670px;
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  &__description {
    color: rgba(60, 60, 67, 0.72);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  &__account {
    color: #1e5cfb;
    text-decoration: none;
  }
  &__spoiler {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__spoiler-content {
    padding-top: 4px;
    padding-left: 32px;
    li {
      color: var(--gray-072);
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
  &__footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-left: 26px;
    padding-top: 20px;
    color: var(--gray-072);
    border-top: 1px solid #f2f2f7;
    li {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
