import { createApp } from "vue";
import { loadLocaleMessages } from "./locales/helper";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./registerServiceWorker";

import "./assets/scss/index.scss";
import "./assets/css/variables.css";

export const i18n = createI18n({
  locale: navigator?.language || "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

const app = createApp(App);

app.use(i18n);
app.mount("#game");
