<template src="./Pagination.html"></template>

<script>
import { computed } from "vue";

export function generatePagination(curr, max) {
  const current = curr,
    last = max,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [];
  let l = 0;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("dots");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

const arrowIcon = `<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.91663 3.75L13.5416 10L7.91662 16.25" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const dotsIcon = `<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="1" cy="1" r="1" fill="#FFFFFF"/>
<circle cx="5" cy="1" r="1" fill="#FFFFFF"/>
<circle cx="9" cy="1" r="1" fill="#FFFFFF"/>
</svg>`;
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 1,
        lastPage: 1,
        limit: 1,
        nextPage: null,
        previousPage: null,
        totalItems: 0,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "change:page"],
  setup(props, ctx) {
    const page = computed(() => props.pagination.currentPage);
    const pages = computed(() =>
      Math.ceil(props.pagination.totalItems / props.pagination.limit)
    );
    const formattedPages = computed(() =>
      generatePagination(page.value, pages.value)
    );

    function setPage(currentPage) {
      ctx.emit("change", {
        ...props.pagination,
        currentPage,
      });
      ctx.emit("change:page", currentPage);
    }

    return {
      arrowIcon,
      dotsIcon,
      formattedPages,
      page,
      pages,
      setPage,
    };
  },
};
</script>

<style lang="scss" src="./Pagination.scss" />
