<template>
  <CLoading v-if="state.isLoading" class="loading" />
  <template v-else-if="state.game && state.game.id">
    <GameHeader
      class="game-header"
      :username="state.game.bots[0].username"
      :link="state.game.link"
      :platform="state.game.platform"
      @clickPoints="isOpenGamePoints = true"
      @clickCondition="isOpenGameCondition = true"
    />
    <GamePoints
      :is-open="isOpenGamePoints"
      :settings="state.game.settings"
      :platform="state.game.platform"
      :bots="state.game.bots"
      :t="$t"
      @close="isOpenGamePoints = false"
    />
    <GameCondition
      :is-open="isOpenGameCondition"
      :condition="state.game.conditions"
      :t="$t"
      @close="isOpenGameCondition = false"
    />
    <GameResults
      class="game-results"
      v-model:search-name="searchName"
      v-model:pagination="state.pagination"
      @update="fetchGameData()"
      :players="state.items"
      :settings="state.game.settings"
      :bots="state.game.bots"
      :platform="state.game.platform"
      :game-loading="loading"
    />
  </template>
  <Game404 v-if="error404" />
</template>

<script>
import GameHeader from "./components/GameHeader.vue";
import GamePoints from "./components/GamePoints";
import GameResults from "./components/GameResults";
import { reactive, ref } from "vue";
import useFetch from "./use/useFetch";
import Game404 from "./components/modules/Game404";
import GameCondition from "./components/GameCondition";
import CLoading from "./components/modules/CLoading/CLoading";

export default {
  name: "App",
  components: {
    CLoading,
    GameCondition,
    Game404,
    GameResults,
    GamePoints,
    GameHeader,
  },
  data() {
    return {
      isOpenGamePoints: false,
      isOpenGameCondition: false,
    };
  },
  setup() {
    const error404 = ref(false);
    const { $get, loading } = useFetch();
    const searchName = ref("");
    const state = reactive({
      game: reactive({
        completedAt: null,
        conditions: "",
        createdAt: null,
        domain: "",
        id: "",
        settings: {
          commentLetterCount: 0,
          commentPoint: 0,
          commentPointMax: 0,
          commentExtraPoints: 0,
          commentExtraTime: 0,
          liveCommentPoint: 0,
          liveCommentPointMax: 0,
          mentionPoint: 0,
          mentionPointMax: 0,
          feedMentionMax: 0,
          reactionPoint: 0,
          reactionPointMax: 0,
          referralPoint: 0,
        },
        startedAt: null,
        status: 0,
        bots: {},
        link: "#",
        platform: "",
      }),
      items: [],
      pagination: {},
      isLoading: true,
    });

    async function fetchGameData() {
      state.isLoading = true;
      const gameSlug = location.pathname.substring(1);
      if (!gameSlug) {
        state.isLoading = false;
        return (error404.value = true);
      }
      try {
        const response = await $get(`/games/${gameSlug}`, {
          query: encodeURI(searchName.value),
          page: state.pagination.currentPage || 1,
        });
        if (!response) {
          state.isLoading = false;
          throw "Error";
        }
        const { game, result } = response;
        if (game) state.game = game;
        if (result) {
          state.pagination = result.pagination;
          state.items = result.items || [];
          state.isLoading = false;
        }
        return response;
      } catch (err) {
        searchName.value = "";
        error404.value = err?.status === 404;
        state.isLoading = false;
      }
    }

    fetchGameData();

    return {
      loading,
      error404,
      state,
      searchName,
      fetchGameData,
    };
  },
  mounted() {
    document.title = this.$t("TITLE");
  },
};
</script>

<style lang="scss">
@import "./assets/scss/respond-mixin";
.game-header {
  margin-bottom: 24px;
  @include respond-to(md) {
    margin-bottom: 36px;
  }
}
.game-rules {
  margin-bottom: 24px;
}
.game-results {
  margin-bottom: 88px;
  @include respond-to(md) {
    margin-bottom: 66px;
  }
}
.loading {
  height: 100vh;
  flex-grow: 1;
}
br {
  display: none;
  @include respond-to(md) {
    display: inline;
  }
}
</style>
