<template>
  <div :class="{ 'Tooltip--open': isOpen }" class="Tooltip">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.Tooltip {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 4;
  background-color: rgba(#000000, 0.9);
  border-radius: 6px;
  padding: 4px 8px;
  transition: opacity 0.2s;
  &--open {
    opacity: 1;
    pointer-events: all;
  }
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 8px;
  }
  p,
  a {
    display: block;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}
</style>
