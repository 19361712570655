<nav class="Pagination">
  <button
    class="btn-reset Pagination__control"
    v-html="arrowIcon"
    :disabled="page === 1 || disabled"
    @click="setPage(page - 1)"
  ></button>
  <button
    class="btn-reset Pagination__page"
    v-for="(num, i) in formattedPages"
    :class="{'current-page': page === num}"
    :disabled="page === num || num === 'dots' || disabled"
    :key="`${num}:${i}`"
    @click="setPage(num)"
    v-html="num === 'dots' ? dotsIcon : num"
  ></button>
  <button
    class="btn-reset Pagination__control"
    v-html="arrowIcon"
    :disabled="page === pages || disabled"
    @click="setPage(page + 1)"
  ></button>
</nav>
