<script>
export default {
  name: "Popup",
  emits: ["close"],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  watch: {
    isActive(newVal) {
      newVal ? this.scrollDisable() : this.scrollEnable();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  unmounted() {
    this.scrollEnable();
    window.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onKeydown(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },
    scrollDisable() {
      document.body.style.overflow = "hidden";
    },
    scrollEnable() {
      document.body.style.overflow = "auto";
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-if="isActive" :class="[`popup_${name}`]" class="popup">
      <div @click="close" class="popup__overlay" />
      <div class="popup__scroll-container">
        <div class="popup__content-wrapper">
          <div class="popup__content">
            <div @click="close" class="popup__close">
              <img
                src="@/assets/svg/cross-filled.svg"
                alt=""
                class="popup__icon-close"
              />
            </div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
@import "@/assets/scss/respond-mixin";
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 15;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: black;
  }
  &__scroll-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 100%;
    @include respond-to(lg) {
      width: auto;
    }
  }
  &__content-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    flex-flow: column;
    @include respond-to(lg) {
      width: auto;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
  }
  &__content {
    position: relative;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    flex: 0 0 auto;
    border-radius: 20px;
    margin: 20px;
    @include respond-to(lg) {
      z-index: 2;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
