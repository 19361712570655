<template>
  <header :class="{ 'GameHeader--menu-active': isOpenMenu }" class="GameHeader">
    <img
      v-if="isOpenMenu"
      src="@/assets/svg/cross.svg"
      alt=""
      class="GameHeader__button-menu"
      @click="isOpenMenu = false"
    />
    <img
      v-else
      src="@/assets/svg/menu.svg"
      alt=""
      class="GameHeader__button-menu"
      @click="isOpenMenu = true"
    />
    <div class="GameHeader__menu">
      <div @click="$emit('clickCondition')" class="GameHeader__link">
        <img src="@/assets/img/prize.svg" alt="" />
        <span v-text="$t('BTN_CONDITION')" />
      </div>
      <div @click="$emit('clickPoints')" class="GameHeader__link">
        <img src="@/assets/img/cup.svg" alt="" />
        <span v-text="$t('BTN_POINTS')" />
      </div>
    </div>
    <a
      class="GameHeader__button-start"
      :href="createLink(username)"
      target="_blank"
    >
      <img src="@/assets/img/cube.svg" alt="" />
      <span v-text="$t('BTN_START')" />
    </a>
  </header>
</template>

<script>
export default {
  name: "GameHeader",
  props: {
    username: String,
    link: String,
    platform: String,
  },
  data() {
    return {
      isOpenMenu: false,
    };
  },
  methods: {
    createLink() {
      let link = process.env.VUE_APP_GAMIFICATION_IG_CREATE_URL;
      const locale = this.$i18n.locale.substring(0, 2);
      if (locale !== "en") {
        link = link + "/" + locale;
      }
      return link + "?utm_source=" + this.username;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/respond-mixin";
.GameHeader {
  $this: &;
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  max-width: var(--max-width-content);
  padding: 0 16px;
  margin: 0 auto;
  @include respond-to(md) {
    height: 76px;
    padding: 0 20px;
  }
  &--menu-active {
    background-color: var(--base-color);
    #{$this}__menu {
      display: block;
      @include respond-to(md) {
        display: flex;
      }
    }
    #{$this}__button-start {
      display: none;
      @include respond-to(md) {
        display: flex;
      }
    }
  }

  &__menu {
    display: none;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    padding: 12px 16px 32px;
    background-color: var(--base-color);
    border-bottom: 2px solid var(--white-color);
    @include respond-to(md) {
      display: flex;
      align-items: center;
      position: static;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  &__button-menu {
    width: 24px;
    height: 24px;
    @include respond-to(md) {
      display: none;
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    width: max-content;
    margin-bottom: 24px;
    @include respond-to(md) {
      margin-bottom: 0;
      margin-right: 40px;
    }
    @include respond-to(lg) {
      &:hover {
        span {
          border-color: transparent;
        }
      }
    }
    span {
      border-bottom: 1px solid var(--white-color);
    }
    img {
      object-fit: contain;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  &__button-start {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--white-color);
    font-weight: 900;
    font-size: 14px;
    height: 40px;
    padding: 0 16px;
    margin-left: auto;
    border-radius: 8px;
    border: 1px solid var(--white-color);
    &:hover {
      opacity: 0.9;
    }
    @include respond-to(md) {
      font-size: 16px;
      padding: 0 24px;
    }
    img {
      object-fit: contain;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
</style>
