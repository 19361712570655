<template>
  <div class="Game404">
    <div class="Game404__title">
      <h2>404</h2>
      <h3>{{ this.$t("NOT_FOUND") }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Game404",
  setup() {},
};
</script>

<style lang="scss">
.Game404 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  h2 {
    font-size: 36px;
    margin-bottom: 8px;
    color: var(--white-color);
  }
  h3 {
    font-size: 18px;
    color: var(--white-color);
  }
}
</style>
