<template>
  <label class="SearchInput">
    <transition name="fade" mode="out-in">
      <span class="SearchInput__preloader" v-if="loading"></span>
      <svg
        class="SearchInput__icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-else
      >
        <path
          d="M7.69236 2.80122C10.9917 2.80122 13.6091 5.35201 13.6091 8.42954C13.6091 11.507 10.9917 14.0577 7.69236 14.0577C4.39301 14.0577 1.77564 11.507 1.77564 8.42954C1.77564 5.35201 4.39304 2.80122 7.69236 2.80122Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9702 11.9699C12.3708 11.5694 13.0202 11.5694 13.4208 11.9699L17.7722 16.3214C18.1728 16.7219 18.1728 17.3715 17.7722 17.7719C17.3717 18.1724 16.7223 18.1724 16.3217 17.7719L11.9702 13.4204C11.5697 13.0201 11.5697 12.3706 11.9702 11.9699Z"
          fill="currentColor"
        />
      </svg>
    </transition>
    <input
      class="SearchInput__input"
      type="search"
      :placeholder="title()"
      :value="modelValue"
      :disabled="loading"
      @input="onInput"
    />
  </label>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    modelValue: String,
    loading: Boolean,
  },
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    function onInput(evt) {
      const value = evt.target.value?.trim() || "";
      emit("update:modelValue", value);
    }

    return {
      onInput,
    };
  },
  methods: {
    title() {
      return this.$t("SEARCH");
    },
  },
};
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.SearchInput {
  $iconSize: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);

  &__preloader,
  &__icon {
    width: $iconSize;
    height: $iconSize;
    flex-shrink: 0;
    margin-right: 6px;
  }

  &__preloader {
    display: block;
    border: 1px solid var(--base-color);
    border-radius: $iconSize;
    border-top-color: transparent;
    animation: spin 1s ease infinite;
  }
  &__icon {
  }

  &__input {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    appearance: none;
    outline: none;
    color: var(--white-color);
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 0;

    &::placeholder {
      font-family: var(--font);
      color: var(--white-color);
      font-size: 16px;
      font-weight: 400;
    }
  }
  .fade {
    @keyframes fade {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    &-enter-active {
      animation: fade 300ms;
    }
    &-leave-active {
      animation: fade 150ms reverse;
    }
  }
}
</style>
